import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/MDXPageLayout.js";
import PageBanner from '../../components/PageBanner';
import Button from '../../components/Button';
import HackavizResults from '../../components/HackavizResults';
import HackavizHeader from '../../components/HackavizHeader';
import HackavizFooter from '../../components/HackavizFooter';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageBanner styleId={1} title={'Hackaviz'} mdxType="PageBanner" />
    <section id="one">
      <div className="inner">
        <h1>{`HACKAVIZ 2022`}</h1>
        <p>{`Cinquième édition
L’association Toulouse Dataviz (TDV) organise son HACKAVIZ. Concours de visualisation de données en temps limité, seul ou en équipe, doté de récompenses, ouvert à un très large public.`}</p>
        <HackavizHeader year={'2022'} mdxType="HackavizHeader" />
        <HackavizResults currentHackaviz={'2022'} mdxType="HackavizResults" />
        <HackavizFooter year={'2022'} mdxType="HackavizFooter" />
      </div>
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      